
import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from '@layout/header/header-one'
import Footer from "@layout/footer/footer-one";

import SkillArea from '@containers/why-choose-us/our-skills'
import PageHeader from "@components/pageheader";
import AboutArea from '@containers/index-processing/about-area'
import ContactArea from "@containers/index-infotechno/contact-area"
import {FormattedMessage,useIntl } from "gatsby-plugin-react-intl"

import slide2 from "@assets/images/architect/1.jpg";
import slide3 from "@assets/images/architect/2.jpg";
import slide4 from "@assets/images/architect/3.jpg";
import slide5 from "@assets/images/architect/4.jpg";
import slide6 from "@assets/images/architect/5.jpg";

const EngineeringDesign = ({location,pageContext}) => {
	const content = [
		
		{
			image: slide2,
		  },
		  {
			image: slide3,
		  },
		  {
			image: slide4,
		  },
		  
		  {
			image: slide5,
		  },
		  {
			image: slide6,
		  },
		  
		
	  ]
	
	  const srviceTexts=[
		  {
			  text:<FormattedMessage id="building_design" />,
			  link:"/engineering-designs/new-design"
		  },
		  {
			text:<FormattedMessage id="modification_of_the_design_and_rehabilitation_existing_buildings" />,
			link:"/engineering-designs/modifications-reahbititions"
		},
		{
			text:<FormattedMessage id="interior_designs" />,
			link:"/engineering-designs/interior-designs"
		},
		{
			text:<FormattedMessage id="rehabilitation_of_heritage_buildings_and_historical_sites" />,
			link:"/engineering-designs/Rehabilitation-heritage-buildings"
		}
	  ]
	  const classnameclass="pt-0"
	  const intl = useIntl()
	  const serviceTitles = intl.formatMessage({
		id: "engineering_designs",
	  })
	return ( 
		<Layout location={location}>
		<Seo title="Services" />
		<Header />

		<main className="site-wrapper-reveal">
			
			{/* <HeroArea /> */}
			<PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
		
			pageTitle="services"
		/>
		<SkillArea />
			<AboutArea content={content}  srviceTexts={srviceTexts} isStudyRequest={false} serviceTitles={serviceTitles} classnameclass={classnameclass} />		
			<ContactArea />
		</main>
		<Footer />
	</Layout>
	 );
}
 
export default EngineeringDesign;

