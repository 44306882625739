import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";
import Heading from "@ui/heading";
import Text from "@ui/text";
import {  FormattedMessage } from "gatsby-plugin-react-intl"
import { Container, Row, Col } from "@ui/wrapper";
import { SectionWrap, SectionTitle} from "./style";

const OurSkills = props => {
	const getSkillData = useStaticQuery(graphql`
        query GET_SKILL_DATA {
            pageIntro: whyChooseUsJson(id: {eq: "why-choose-page-intro"}) {
                title
                desc
                heading
                text
            }
            skills: whyChooseUsJson(id: {eq: "our-skills"}) {
                skills {
                    title
                    value
                }
            }
        }
    `)
	const { pageIntro: { title } } = getSkillData
	const { titleStyle, descStyle } = props;
	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={12}>
						<SectionTitle>
							{title && <Heading {...titleStyle}><FormattedMessage id="about_engineering_designs" /></Heading>}
							 <Text {...descStyle}><FormattedMessage id="endineering_designs_desc" /></Text>
						</SectionTitle>
					</Col>
				</Row>
			
			</Container>
		</SectionWrap>
	)
}
OurSkills.propTypes = {
	titleStyle: PropTypes.object,
	descStyle: PropTypes.object,
	headingStyle: PropTypes.object,
	textStyle: PropTypes.object
}

OurSkills.defaultProps = {
	titleStyle: {
		as: 'h3',
		mb: '20px',
		child: {
			color: 'primary'
		}
	},
	descStyle: {
		maxwidth: '760px',
		m: 'auto'
	},
	headingStyle: {
		as: "h3",
		layout: "highlight",
		maxwidth: "330px",
		mb: "24px",
		responsive: {
			medium: {
				maxwidth: "100%"
			}
		}
	},
	textStyle: {
		as: "h4",
		fontSize: "38px",
		child: {
			color: 'primary'
		},
		responsive: {
			medium: {
				fontSize: "28px"
			},
			small: {
				fontSize: "24px"
			}
		}
	},
}


export default OurSkills
